import { Injectable, Injector } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { FilterService } from 'src/app/features/header/services/filter.service';
import {
  AI_AccountEvents,
  AppInsightsService,
} from 'src/app/services/appinsights.service';
import {
  PublicClientApplication,
  BrowserCacheLocation,
} from '@azure/msal-browser';

import { JwtHelperService } from '@auth0/angular-jwt';
import { DataSharingService } from './data-sharing.service';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private router: Router;
  private authServiceMicro: MsalService;
  public http: HttpClient;
  private authService: AuthenticationService;
  private filterservice: FilterService;
  private appInsightService: AppInsightsService;
  private jwtHelper: JwtHelperService;
  private dataSharingService: DataSharingService;
  private window: Window;
  encryId: any;

  constructor(private injector: Injector) {
    this.router = injector.get<Router>(Router);
    this.authServiceMicro = injector.get<MsalService>(MsalService);
    this.http = injector.get<HttpClient>(HttpClient);
    this.authService = injector.get<AuthenticationService>(
      AuthenticationService
    );
    this.filterservice = injector.get<FilterService>(FilterService);
    this.appInsightService =
      injector.get<AppInsightsService>(AppInsightsService);
    this.jwtHelper = injector.get<JwtHelperService>(JwtHelperService);
    this.dataSharingService =
      injector.get<DataSharingService>(DataSharingService);
    this.window = injector.get<Window>(Window);
  }

  microSoftAccount: any;
  redirectURL: any;
  isIframe: any;
  currentSession: string = '';
  /* istanbul ignore next */
  public signInWithGoogle(previousUrl: any, encryId: any) {
    this.encryId = encryId
    sessionStorage.clear();
    localStorage.clear();
    this.dataSharingService.isLoginProgress.next(true);
    const isIE =
      window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      window.navigator.userAgent.indexOf('Trident/') > -1;
    const config = {
      auth: {
        clientId: environment.B2CClientId, // This is the ONLY mandatory field that you need to supply.
        authority: environment.B2CAuthUrl + environment.googleB2C, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [environment.B2CKnownAuthorities], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
      },
    };

    const loginRequest = {
      endpoint: environment.base_api_url,
      scopes: ['openid', 'profile'],
    };

    const myMsal = new PublicClientApplication(config);

    myMsal
      .loginPopup(loginRequest)
      .then((loginResponse) => {
        var googleAccount: any = loginResponse.account?.idTokenClaims;

        if (googleAccount) {
          var parsedResponse = JSON.stringify(googleAccount);
          const parsedJSON = JSON.parse(parsedResponse);
          var googleUser: any = {
            email: parsedJSON.email,
            userName: parsedJSON.name,
          };
          localStorage.setItem('googleAccount', JSON.stringify(googleUser));
          localStorage.setItem('tokenExpiresOn', googleAccount.exp);
          localStorage.setItem('authToken', loginResponse.idToken);
          this.getUserStatus(parsedJSON.email, previousUrl);
        }
      })
      .catch((error) => {
        this.dataSharingService.isLoginProgress.next(false);
      });
  }
  /* istanbul ignore next */
  public signInwithMicroSoft(previousUrl: any, encryId: any) {
    this.encryId = encryId
    sessionStorage.clear();
    localStorage.clear();
    this.dataSharingService.isLoginProgress.next(true);
    this.authServiceMicro.loginPopup().subscribe(
      (res: any) => {
        this.microSoftAccount = res.account;

        var jwtToken = this.microSoftAccount.idTokenClaims
          ? this.microSoftAccount.idTokenClaims.idp_access_token
          : '';
        var token: any = '';
        try {
          token = this.jwtHelper.decodeToken(jwtToken);
          var microSoftUser: any = {
            email: token.unique_name,
            userName: token.name,
          };
          localStorage.setItem(
            'microSoftAccount',
            JSON.stringify(microSoftUser)
          );
          localStorage.setItem(
            'tokenExpiresOn',
            this.microSoftAccount.idTokenClaims.exp
          );
          localStorage.setItem('authToken', res.idToken);
          this.currentSession = 'microSoft';
          this.getUserStatus(microSoftUser.email, previousUrl);
        } catch (ex) {
          localStorage.setItem('outlook', res.idTokenClaims.idp_access_token);
          setTimeout(() => {
            this.authService.outlookUserInfo().subscribe((outlook: any) => {
              microSoftUser = {
                email: outlook.email,
                userName: outlook.name,
              };
              localStorage.setItem(
                'microSoftAccount',
                JSON.stringify(microSoftUser)
              );
              localStorage.setItem(
                'tokenExpiresOn',
                this.microSoftAccount.idTokenClaims.exp
              );
              localStorage.setItem('authToken', res.idToken);
              this.currentSession = 'microSoft';
              this.getUserStatus(microSoftUser.email, previousUrl);
              setTimeout(() => {
                localStorage.removeItem('outlook');
              }, 2000);
            });
          }, 100);
        }
      },
      (error) => {
        this.dataSharingService.isLoginProgress.next(false);
      }
    );
  }
  /* istanbul ignore next */
  getUserStatus(email: any, previousUrl: any) {
    this.authService.getUser(email).subscribe(
      (res: any) => {
        if (res.result.user) {
          let loginStatus = res.result.user.status;
          switch (loginStatus) {
            case null:
              this.dataSharingService.isLoginProgress.next(false);
              localStorage.setItem('userStatus', '-1');
              this.window.location.href =
                environment.localUrl + '/login/account-not-found';
              break;
            case '0':
              this.dataSharingService.isLoginProgress.next(false);
              localStorage.setItem('userStatus', loginStatus);
              this.router.navigate([`/login/account-deactivated`]);
              break;
            case '1':
              if (
                res.result.accountUserRole.status === 'Active' ||
                res.result.isUserGroupDefault ||
                res.result.user.userType === 'systemadmin'
              ) {
                this.UserStatusCase1Apply(loginStatus, previousUrl, res.result);
              } else {
                this.dataSharingService.isLoginProgress.next(false);
                localStorage.setItem('userEmail', email);
                localStorage.setItem('userStatus', '0');
                this.router.navigate([`/login/account-deactivated`]);
              }
              break;
            case '2':
              this.dataSharingService.isLoginProgress.next(false);
              localStorage.setItem('userEmail', email);
              localStorage.setItem('userStatus', loginStatus);
              this.router.navigate([`/login/account-pending`]);
              break;
            default:
              this.router.navigate([`/`]);
          }
        } else {
          this.dataSharingService.isLoginProgress.next(false);
          localStorage.setItem('userStatus', '-1');

          this.window.location.href =
            environment.localUrl + '/login/account-not-found';
        }
      },
      (error) => {
        this.dataSharingService.isLoginProgress.next(false);
      }
    );
  }
  /* istanbul ignore next */
  getUserStatusForRegistration(email: any, user: any) {
    this.authService.getUser(email).subscribe(
      (res: any) => {
        if (res.result.user) {
          let loginStatus = res.result.user.status;
          if (loginStatus) {
            switch (loginStatus) {
              case '0':
                this.dataSharingService.isLoginProgress.next(false);
                localStorage.setItem('userStatus', loginStatus);
                this.router.navigate([`/login/account-deactivated`]);
                break;
              case '1':
                if (
                  res.result.accountUserRole.status === 'Active' ||
                  res.result.isUserGroupDefault ||
                  res.result.user.userType === 'systemadmin'
                ) {
                  this.UserStatusCase1Apply(loginStatus, '', res.result);
                } else {
                  this.appInsightService.logEvent(
                    AI_AccountEvents.AccountDeActivated
                  );
                  this.dataSharingService.isLoginProgress.next(false);
                  localStorage.setItem('userEmail', email);
                  localStorage.setItem('userStatus', '0');
                  this.router.navigate([`/login/account-deactivated`]);
                }
                break;
              case '2':
                this.dataSharingService.isLoginProgress.next(false);
                localStorage.setItem('userStatus', loginStatus);
                localStorage.setItem('userEmail', email);
                this.router.navigate([`/login/account-pending`]);
                break;
              default:
                this.dataSharingService.isLoginProgress.next(false);
                this.router.navigate([`/`]);
            }
          } else {
            this.dataSharingService.isLoginProgress.next(false);
            this.router.navigate(['/login/sign-up'], {
              queryParams: user,
              skipLocationChange: true,
            });
          }
        } else {
          this.dataSharingService.isLoginProgress.next(false);
          this.router.navigate(['/login/sign-up'], {
            queryParams: user,
            skipLocationChange: true,
          });
        }
      },
      (error) => {
        this.dataSharingService.isLoginProgress.next(false);
      }
    );
  }
  /* istanbul ignore next */
  public signInWithGoogleNotFoundUser() {
    localStorage.clear();
    sessionStorage.clear();
    this.dataSharingService.isLoginProgress.next(true);
    const isIE =
      window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      window.navigator.userAgent.indexOf('Trident/') > -1;
    const config = {
      auth: {
        clientId: environment.B2CClientId, // This is the ONLY mandatory field that you need to supply.
        authority: environment.B2CAuthUrl + environment.googleB2C, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [environment.B2CKnownAuthorities], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
      },
    };

    const loginRequest = {
      endpoint: environment.base_api_url,
      scopes: ['openid', 'profile'],
    };

    let accountId = '';

    const myMsal = new PublicClientApplication(config);
    myMsal
      .loginPopup(loginRequest)
      .then((response) => {
        var googleAccount: any = response.account?.idTokenClaims;
        if (googleAccount) {
          var parsedResponse = JSON.stringify(googleAccount);
          const parsedJSON = JSON.parse(parsedResponse);
          var firstName = '';
          var lastName = '';
          var fullName = '';
          var emailSplit: any = [];
          var fullNameSplit: any = [];
          if (parsedJSON.name) {
            if (parsedJSON.name.includes(',')) {
              parsedJSON.name.replace(',', '');
            }
          }
          if (parsedJSON.given_name) {
            if (parsedJSON.given_name.includes(',')) {
              parsedJSON.given_name.replace(',', '');
            }
          }
          if (parsedJSON.family_name) {
            if (parsedJSON.family_name.includes(',')) {
              parsedJSON.family_name.replace(',', '');
            }
          }
          if (
            !parsedJSON.given_name &&
            !parsedJSON.family_name &&
            !parsedJSON.name
          ) {
            emailSplit = parsedJSON.email.split('@');
            firstName = emailSplit[0];
          } else if (
            !parsedJSON.given_name &&
            !parsedJSON.family_name &&
            parsedJSON.name
          ) {
            fullNameSplit = parsedJSON.name.split(' ');
            firstName = fullNameSplit[0];
            for (let i = 1; i < fullNameSplit.length; i++) {
              lastName += fullNameSplit[i] + ' ';
            }
            fullName = parsedJSON.name ? parsedJSON.name : '';
          } else if (parsedJSON.given_name || parsedJSON.family_name) {
            firstName = parsedJSON.given_name ? parsedJSON.given_name : '';
            lastName = parsedJSON.family_name ? parsedJSON.family_name : '';
            fullName = parsedJSON.name ? parsedJSON.name : '';
          }
          var googleUser: any = {
            email: parsedJSON.email,
            firstName: firstName,
            lastName: lastName,
            fullName: fullName,
          };
          if (!localStorage.getItem('googleAccount')) {
            var currentGoogleUser: any = {
              email: parsedJSON.email,
              userName: parsedJSON.name,
            };
            this.currentSession = 'google';
            localStorage.setItem(
              'googleAccount',
              JSON.stringify(currentGoogleUser)
            );
            localStorage.setItem('tokenExpiresOn', googleAccount.exp);
            localStorage.setItem('authToken', response.idToken);
          }

          this.getUserStatusForRegistration(parsedJSON.email, googleUser);
        }
      })
      .catch((error) => {
        this.dataSharingService.isLoginProgress.next(false);
      });
  }
  /* istanbul ignore next */
  public signInWithMicroSoftNotFoundUser() {
    localStorage.clear();
    sessionStorage.clear();
    this.dataSharingService.isLoginProgress.next(true);
    this.authServiceMicro.loginPopup().subscribe(
      (res: any) => {
        if (res) {
          var jwtToken = res.idTokenClaims
            ? res.idTokenClaims.idp_access_token
            : '';
          var token: any = '';
          try {
            token = this.jwtHelper.decodeToken(jwtToken);
            this.microSoftRegUser(token, res);
          } catch (ex) {
            localStorage.setItem('outlook', res.idTokenClaims.idp_access_token);
            setTimeout(() => {
              this.authService.outlookUserInfo().subscribe((outlook: any) => {
                this.microSoftRegUser(outlook, res);
                setTimeout(() => {
                  localStorage.removeItem('outlook');
                }, 2000);
              });
            }, 100);
          }
        }
      },
      (error) => {
        this.dataSharingService.isLoginProgress.next(false);
      }
    );
  }
  /* istanbul ignore next */
  microSoftRegUser(token: any, res: any) {
    var firstName = '';
    var lastName = '';
    var fullName = '';
    var emailSplit: any = [];
    var fullNameSplit: any = [];
    if (token.name) {
      if (token.name.includes(',')) {
        token.name.replace(',', '');
      }
    }
    if (token.given_name) {
      if (token.given_name.includes(',')) {
        token.given_name.replace(',', '');
      }
    }
    if (token.family_name) {
      if (token.family_name.includes(',')) {
        token.family_name.replace(',', '');
      }
    }
    if (!token.given_name && !token.family_name && !token.name) {
      emailSplit = token.email.split('@');
      firstName = emailSplit[0];
    } else if (!token.given_name && !token.family_name && token.name) {
      fullNameSplit = token.name.split(' ');
      firstName = fullNameSplit[0];
      for (let i = 1; i < fullNameSplit.length; i++) {
        lastName += fullNameSplit[i] + ' ';
      }
      fullName = token.name ? token.name : '';
    } else if (token.given_name || token.family_name) {
      firstName = token.given_name ? token.given_name : '';
      lastName = token.family_name ? token.family_name : '';
      fullName = token.name ? token.name : '';
    }
    var microSoftUser: any = {
      email: token.unique_name ? token.unique_name : token.email,
      firstName: firstName,
      lastName: lastName,
      fullName: fullName,
    };
    if (!localStorage.getItem('microSoftAccount')) {
      var currentMicroSoftUser: any = {
        email: token.unique_name ? token.unique_name : token.email,
        userName: token.name,
      };
      this.currentSession = 'microSoft';
      localStorage.setItem(
        'microSoftAccount',
        JSON.stringify(currentMicroSoftUser)
      );
      localStorage.setItem('tokenExpiresOn', res.idTokenClaims.exp);
      localStorage.setItem('authToken', res.idToken);
    }
    this.getUserStatusForRegistration(microSoftUser.email, microSoftUser);
  }
  /* istanbul ignore next */
  public async logout() {
    if (localStorage.getItem('googleAccount')) {
      sessionStorage.clear();
      localStorage.clear();
      localStorage.removeItem('googleAccount');
      this.authService.logout();
      this.window.location.href = environment.localUrl + '/login/log-in';
    }
    if (localStorage.getItem('microSoftAccount')) {
      sessionStorage.clear();
      localStorage.clear();
      localStorage.removeItem('microSoftAccount');
      this.window.location.href = environment.localUrl + '/login/log-in';
    }
  }
  /* istanbul ignore next */
  UserStatusCase1Apply(loginStatus: any, previousUrl: any, res: any) {
    localStorage.setItem('userStatus', loginStatus);
    var redirectURL = previousUrl ? previousUrl : '/dashboard';
    let userDetails = res.user;
    let userRole = res.accountUserRole;
    let user: any = {
      fullName: userDetails.fullName,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      companyName: userDetails.companyName,
      emailAddress: userDetails.emailAddress,
      oIAContactName: userDetails.oIAContactName,
      oIAContactEmail: userDetails.oIAContactEmail,
      roleId: userRole ? userRole?.roleId : '',
      userType: userDetails.userType,
      accountId: userRole ? userRole?.accountId : '',
      accountAliasName: userRole ? userRole?.aliasName : '',
      featureList: userRole ? userRole?.features : [],
      profilePic: userDetails?.profilePicture,
      imagecontent: userDetails?.imagecontent,
      isGroup: res.isUserGroupDefault ? res.isUserGroupDefault : false,
      isParent: res.isControllingCustomer ? res.isControllingCustomer : false,
      guidedTour: userDetails.guidedTour,
      joinedDate: userDetails.joinedDate,
      mobile: userDetails.mobile,
    };
    if (userDetails.userType == 'systemadmin') {
      this.filterservice
        .getAutoCompleteSearchRecords('', 0, 1)
        .subscribe((accountRes: any) => {
          let adminAcc = accountRes.result[0];
          let shipmentFeatureArray = accountRes.result[0].shipment;
          let bookingFeatureArray = accountRes.result[0].booking;
          let customsFeatureArray = accountRes.result[0].customs;
          let reportFeatureArray = accountRes.result[0].reports;
          var customFeatureList: any = [
            { featureName: 'Dashboard', isVisible: true },
            {
              featureName: 'Shipments',
              isVisible:
                shipmentFeatureArray != null
                  ? shipmentFeatureArray.isVisible
                  : false,
            },
            {
              featureName: 'Bookings',
              isVisible:
                bookingFeatureArray != null
                  ? bookingFeatureArray.isVisible
                  : false,
            },
            {
              featureName: 'Customs',
              isVisible:
                customsFeatureArray != null
                  ? customsFeatureArray.isVisible
                  : false,
            },
            {
              featureName: 'Report',
              isVisible:
                reportFeatureArray != null
                  ? reportFeatureArray.isVisible
                  : false,
            },
            { featureName: 'MyProfile', isVisible: true },
            { featureName: 'Admin' },
          ];
          if (res.isUserGroupDefault) {
            user['accountAliasName'] = userRole.aliasName;
            user['accountId'] = userRole.accountId;
            user['featureList'] = userRole.features;
            userRole?.features.forEach((e: any) => {
              if (e.featureName == 'Shipments') {
                user.shipmentIsVisible = e.isVisible;
                customFeatureList.shipmentIsVisible = e.isVisible;
              } else if (e.featureName == 'Bookings') {
                user.bookingIsVisible = e.isVisible;
                customFeatureList.bookingIsVisible = e.isVisible;
              } else if (e.featureName == 'Customs') {
                user.customsIsVisible = e.isVisible;
                customFeatureList.customsIsVisible = e.isVisible;
              } else if (e.featureName.trim() == 'Reports') {
                user.reportIsVisible = e.isVisible;
                customFeatureList.reportIsVisible = e.isVisible;
              }
            });
          } else {
            user['accountAliasName'] = adminAcc.aliasName;
            user['accountId'] = adminAcc.oH_Code;
            user['featureList'] = customFeatureList;
            user.shipmentIsVisible =
              shipmentFeatureArray != null
                ? shipmentFeatureArray.isVisible
                : false;
            user.bookingIsVisible =
              bookingFeatureArray != null
                ? bookingFeatureArray.isVisible
                : false;
            user.customsIsVisible =
              customsFeatureArray != null
                ? customsFeatureArray.isVisible
                : false;
            user.reportIsVisible =
              reportFeatureArray != null ? reportFeatureArray.isVisible : false;
          }

          user.isGroup = res.isUserGroupDefault;
          user.isParent = res.isControllingCustomer;
          user.isBookingActionField = res.isBookingActionField;
          user.isCustomActionField = res.isCustomActionField;
          user.isShipmentActionField = res.isShipmentActionField;
          var customUserFeature = {
            accountId: !res.isUserGroupDefault
              ? adminAcc.oH_Code
              : userRole.accountId,
            accountAliasName: !res.isUserGroupDefault
              ? adminAcc.aliasName
              : userRole.aliasName,
            featureList: !res.isUserGroupDefault
              ? customFeatureList
              : userRole.features,
            isGroup: res.isUserGroupDefault,
            isParent: res.isControllingCustomer,
            shipmentIsVisible: user.shipmentIsVisible,
            bookingIsVisible: user.bookingIsVisible,
            customsIsVisible: user.customsIsVisible,
            isBookingActionField: res.isBookingActionField,
            isCustomActionField: res.isCustomActionField,
            isShipmentActionField: res.isShipmentActionField,
            reportIsVisible: user.reportIsVisible,
          };
          sessionStorage.setItem(
            'userFeatureList',
            JSON.stringify(customUserFeature)
          );
          localStorage.setItem(
            'loggedInUser',
            window.btoa(unescape(encodeURIComponent(JSON.stringify(user))))
          );

          this.appInsightService.setUserAndAccountId(
            user.emailAddress,
            customUserFeature.accountId
          );
          this.appInsightService.logEvent('User Login');
          if (redirectURL != '/dashboard') {
            this.window.location.href = environment.localUrl + '/' +
              this.encryId && this.encryId.booking ? this.encryId.booking.encryptedId :
              this.encryId && this.encryId.shipment ? this.encryId.shipment.encryptedId :
                this.encryId && this.encryId.customs ? this.encryId.customs.encryptedId :
                  redirectURL
            // if (redirectURL == '/bookings') {
            //   this.window.location.href = environment.localUrl + '/' + this.encryId && this.encryId.booking ? this.encryId.booking.encryptedId : redirectURL
            // }
            // else if (redirectURL == '/customs') {
            //   this.window.location.href = environment.localUrl + '/' + this.encryId && this.encryId.customs ? this.encryId.customs.encryptedId : redirectURL
            // }
            // else {
            //   this.window.location.href = environment.localUrl + '/' + this.encryId && this.encryId.shipment ? this.encryId.shipment.encryptedId : redirectURL
            // }
          }
          else {
            this.window.location.href = environment.localUrl + redirectURL;
          }

        });
    } else {
      var userFeature: any = {
        accountId: userRole ? userRole?.accountId : '',
        accountAliasName: userRole ? userRole?.aliasName : '',
        featureList: userRole ? userRole?.features : [],
        isGroup: res.isUserGroupDefault,
        isParent: res.isControllingCustomer,
        isBookingActionField: res.isBookingActionField,
        isCustomActionField: res.isCustomActionField,
        isShipmentActionField: res.isShipmentActionField,
      };
      userRole?.features.forEach((e: any) => {
        if (e.featureName == 'Shipments') {
          user.shipmentIsVisible = e.isVisible;
          userFeature.shipmentIsVisible = e.isVisible;
        } else if (e.featureName == 'Bookings') {
          user.bookingIsVisible = e.isVisible;
          userFeature.bookingIsVisible = e.isVisible;
        } else if (e.featureName == 'Customs') {
          user.customsIsVisible = e.isVisible;
          userFeature.customsIsVisible = e.isVisible;
        } else if (e.featureName.trim() == 'Reports') {
          user.reportIsVisible = e.isVisible;
          userFeature.reportIsVisible = e.isVisible;
        }
      });

      user.isGroup = res.isUserGroupDefault;
      user.isParent = res.isControllingCustomer;
      user.isBookingActionField = res.isBookingActionField;
      user.isCustomActionField = res.isCustomActionField;
      user.isShipmentActionField = res.isShipmentActionField;
      sessionStorage.setItem('userFeatureList', JSON.stringify(userFeature));
      localStorage.setItem(
        'loggedInUser',
        window.btoa(unescape(encodeURIComponent(JSON.stringify(user))))
      );
      this.appInsightService.setUserAndAccountId(
        user.emailAddress,
        userFeature.accountId
      );
      this.appInsightService.logEvent('User Login');
      if (redirectURL != '/dashboard') {

        this.window.location.href = environment.localUrl + '/' +
          this.encryId && this.encryId.booking ? this.encryId.booking.encryptedId :
          this.encryId && this.encryId.shipment ? this.encryId.shipment.encryptedId :
            this.encryId && this.encryId.customs ? this.encryId.customs.encryptedId :
              redirectURL
        // if (redirectURL == '/bookings') {

        // }
        // else if (redirectURL == '/customs') {
        //   this.window.location.href = environment.localUrl + '/' + this.encryId && this.encryId.customs ? this.encryId.customs.encryptedId : redirectURL
        // }
        // else {
        //   this.window.location.href = environment.localUrl + '/' + this.encryId && this.encryId.shipment ? this.encryId.shipment.encryptedId : redirectURL
        // }
      }
      else {
        this.window.location.href = environment.localUrl + redirectURL;
      }

    }
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json; charset=utf-8'
    }),
  };
  getRecaptchaScore(responseToken: any): Observable<any> {
    let request: any = {
      search_text: responseToken
    }
    console.log('request', request)
    console.log('httpOptions', this.httpOptions)
    return this.http
      .post<unknown[]>(
        environment.base_api_desktop_url + "Shipment/validaterecaptcha",
        request,
        this.httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  shipmentPublicSearch(shimpmentNumber: any): Observable<any> {
    let request = {
      offset: 0,
      fetch: 1,
      isGlobalSearch: true,
      parameters: [
        {
          name: 'shipmentNumber',
          value: shimpmentNumber,
        },
      ],
    };
    return this.http
      .post<any[]>(
        environment.base_api_desktop_url + 'Shipment/public_Search',
        request
      )
      .pipe(catchError(this.handelError));
  }

  handelError(error: any) {
    console.log(error, 'error')
    return throwError(
      {
        status: error.status,
        statusText: error.statusText,
      } || 'Server Error'
    );
  }
}
