// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  base_api_desktop_url: 'https://api.oiaconnect.com/OC3-prd/v1/desktop/',
  base_api_url: 'https://api.oiaconnect.com/OC3-prd',
  googleB2C: "B2C_1A_SIGNUP_SIGNIN_GOOGLE",
  microSoftB2C: "B2C_1A_signup_signin_b2cinaad",
  B2CAuthUrl: "https://oiaconnectprod.b2clogin.com/oiaconnectprod.onmicrosoft.com/",
  B2CClientId: "4a9a2d34-70dc-4813-adc6-3e99152bf14a",
  B2CKnownAuthorities: "oiaconnectprod.b2clogin.com",
  localUrl: window.location.origin,
  messageDisplayInSeconds: 3000,
  angular_web_url: window.location.origin,
  appInsights: {
    instrumentationKey: '52947e85-7a19-4be8-a9c2-7b54b98466c6',
  },
  recaptcha: {
    siteKey: '6LefElgnAAAAAOH55pE9shk0nIcfazicr-Hgjyjw',
    scoreRange: 0.4
  },
  canActive: false,
  session: {
    reInitiate: { skewTime: 6 }, // skew time is 6 min
    idleLogoutTime: 6 // idle log out time is 6hrs
  }
};

