import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentSessionDataService } from 'src/app/shared/services/current-session-data.service';
import { environment } from 'src/environments/environment';
import { SwitchAccountService } from 'src/app/shared/services/switch-account.service';
import { ShipmentServies } from '../shipments/services/shipment.service';
import { FilterService } from '../header/services/filter.service';
import { CustomsService } from '../customs/services/customs.service';
import { BookingServies } from '../booking/services/booking.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { Location } from '@angular/common';
import { LoginService } from 'src/app/shared/services/login.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AI_CommonEvents, AI_CustomProps, AI_PageName, AppInsightsService } from 'src/app/services/appinsights.service';
@Component({
  selector: 'app-public-search',
  templateUrl: './public-search.component.html',
  styleUrls: ['./public-search.component.scss'],
})
export class PublicSearchComponent implements OnInit {
  urlShipmentId = '';
  encryptedShipmentId = '';
  selectedShipmentLen: number = 0;
  selectedBookingLen: number = 0;
  selectedCustomsLen: number = 0;
  url = '';
  popupToggleList = false;
  isProgressShow = false;
  publicShareFilter: any;
  popupToggle = false;
  isShipmentFullview = false;
  getShipmentDetails: any = [];
  getBookingDetails: any = [];
  getCustomsDetails: any = [];
  getAccountDetails: any = [];
  copyText: any = '';
  currentUserData: any = this.currentSessionUserData.getCurrentUserData();
  currentAccountData: any = this.currentSessionUserData.getCurrentAccountData();
  isLoading = false;
  isUserHaveAcc: any;
  isShipmentView = false;
  isBookingView = false;
  isCustomsView = false;
  currentModule: string = '';
  userEmail: any;
  resultNotFound = false;
  publicShareEnrcyptedData = '';

  constructor(
    private shipmentService: ShipmentServies,
    private activatedRoute: ActivatedRoute,
    private currentSessionUserData: CurrentSessionDataService,
    private switchAccount: SwitchAccountService,
    private filterService: FilterService,
    private customsService: CustomsService,
    private bookingServies: BookingServies,
    private dataSharingService: DataSharingService,
    private location: Location,
    private recaptchaV3Service: ReCaptchaV3Service,
    private loginService: LoginService,
    private appInsightsService: AppInsightsService,
    private window: Window,
  ) { }

  ngOnInit(): void {
    this.trackAIPageView();
    let shipmentId = this.activatedRoute.snapshot.params['searchParam'];
    if (shipmentId) {
      this.urlShipmentId = shipmentId.trim();
      //this.publicShareShipment(shipmentId.trim());
      if (localStorage.getItem('tokenExpiresOn')) {
        this.publicShareShipment(shipmentId.trim());
      }
      else {
        this.shipmentPublicSearch(shipmentId);
      }

    }
  }

  /*** captcha search */
  shipmentPublicSearch(shipmentId: any) {
    this.recaptchaV3Service
      .execute('importantAction')
      .subscribe((token: string) => {
        //console.debug(`Token [${token}] generated`);
        this.getRecpatchaScore(token, shipmentId);
      });
  }

  getRecpatchaScore(response: any, shipmentId: any) {
    this.loginService.getRecaptchaScore(response).subscribe((e: any) => {
      let result = JSON.parse(e.result);
      if (result.success && result.score >= environment.recaptcha.scoreRange) {
        this.publicShareShipment(shipmentId.trim());
      } else {
        alert('Access Denied!');
      }
    });
  }

  /*** captcha search end*/

  detailShare(event: any) {
    this.url = environment.localUrl + '/' + this.encryptedShipmentId;
    this.popupToggleList = true;
  }

  publicShareShipment(shipmentId: any) {
    this.currentModule = '';
    this.isLoading = true;
    this.setSessionData();
    if (!this.currentUserData) {
      this.dataSharingService.notLoggedIn.next(true);
    }
    this.shipmentService
      .shipmentPublicSearchUrl(shipmentId, this.userEmail)
      .subscribe(
        (x: any) => {
          if (x.result) {
            this.encryptedShipmentId = x?.result?.encryptedId;
            //Update url
            history.pushState(history.state, document.title, window.location.origin + "/" + (this.encryptedShipmentId ?? ""));

            if (x.result.booking && !x.result.shipment_converted_from_booking) {
              this.isBookingView = true;
              this.currentModule = 'booking';
              this.bookingResult(x.result);
            } else if (
              x.result.forwarder_reference.charAt(0).toLowerCase() === 's'
            ) {
              this.currentModule = 'shipment';
              this.isShipmentView = true;
              this.shipmentResult(x.result);
            } else if (
              x.result.forwarder_reference.charAt(0).toLowerCase() === 'b'
            ) {
              this.currentModule = 'customs';
              this.isCustomsView = true;
              this.customsResult(x.result);
            }
            this.setIsDetailFullView(true);
          } else {
            this.resultNotFound = true;
            this.isLoading = false;
            this.setIsDetailFullView(false);
          }
          this.trackAppInsightEvent(x?.result?.forwarder_reference, x?.result);
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  setIsDetailFullView(flag: boolean) {
    this.shipmentService.isFullScreenTabEnabled = flag;
    this.bookingServies.isFullScreenTabEnabled = flag;
    this.customsService.isFullScreenTabEnabled = flag;
  }

  sharePopUpfeatureHide() {
    this.popupToggleList = false;
  }

  copyLink() {
    this.copyText = document.getElementById('shareUrl');
    this.copyText.select();
    this.copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  switchAccountAdmin(shipment: any) {
    if (Object.keys(shipment).length > 0) {
      let accountDetail = {
        aliasName: shipment.account_alias_name,
        oH_Code: shipment.accountId,
        byGroup: false,
        isControllingCustomer: false,
        shipment: {
          isVisible: shipment?.account_shipment?.isVisible,
        },
        booking: {
          isVisible: shipment?.account_booking?.isVisible,
        },
        customs: {
          isVisible: shipment?.account_customs?.isVisible,
        },
      };
      const isSwitchAccount = this.switchAccount.switchAccount(
        accountDetail,
        '',
        this.urlShipmentId
      );
      if (!isSwitchAccount) {
        if (this.isShipmentView) {
          this.selectedListShipment(shipment);
        } else if (this.isBookingView) {
          this.selectedListBooking(shipment);
        } else if (this.isCustomsView) {
          this.selectedListCustoms(shipment);
        }
        setTimeout(() => {
          this.popupToggle = true;
          this.isLoading = false;
        }, 0);
      }
    }
  }

  switchAccountUser(shipment: any) {
    let accountDetail = {
      aliasName: shipment.account_alias_name,
      oH_Code: shipment.accountId,
      byGroup: false,
      isControllingCustomer: false,
      shipment: {
        isVisible: shipment?.account_shipment?.isVisible,
      },
      booking: {
        isVisible: shipment?.account_booking?.isVisible,
      },
      customs: {
        isVisible: shipment?.account_customs?.isVisible,
      },
    };
    if (accountDetail?.oH_Code) {
      this.filterService
        .getData(
          environment.base_api_desktop_url +
          'useraccount?userEmailAddresss=' +
          this.currentUserData.emailAddress
        )
        .subscribe((res) => {
          this.isUserHaveAcc = res.result
            .map(function (x: any) {
              return x.oH_Code;
            })
            .indexOf(accountDetail.oH_Code);
          if (this.isUserHaveAcc >= 0) {
            const isSwitchAccount = this.switchAccount.switchAccount(
              accountDetail,
              '',
              this.urlShipmentId
            );
            if (!isSwitchAccount) {
              if (this.isShipmentView) {
                this.selectedListShipment(shipment);
              } else if (this.isBookingView) {
                this.selectedListBooking(shipment);
              } else if (this.isCustomsView) {
                this.selectedListCustoms(shipment);
              }
              setTimeout(() => {
                this.popupToggle = true;
                this.isLoading = false;
              }, 0);
            }
          }
        });
    }
  }

  selectedListShipment(shipment: any, from?: any) {
    this.isProgressShow = true;
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    this.shipmentService
      .getShipmentsDetails(
        accountId,
        emailId,
        shipment.forwarder_reference,
        isGroup
      )
      .subscribe({
        next: (getDetails: any) => {
          if (from === 'refresh') {
            this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              true
            );
          }
          if (getDetails.result) {
            this.isProgressShow = false;
            this.getShipmentDetails = getDetails.result[0];
            this.selectedShipmentLen = Object.keys(
              this.getShipmentDetails
            ).length;
          } else {
            this.isProgressShow = false;
            //shipment.isFullViewAccess = false;
            this.getShipmentDetails = shipment;
            this.selectedShipmentLen = Object.keys(
              this.getShipmentDetails
            ).length;
          }
        },
      });
  }

  selectedListCustoms(customs: any, from?: any) {
    this.isProgressShow = true;
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    this.customsService
      .getCustomDetails(
        accountId,
        emailId,
        customs.forwarder_reference,
        isGroup
      )
      .subscribe({
        next: (getDetails: any) => {
          if (from === 'refresh') {
            this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              true
            );
          }
          if (getDetails.result) {
            this.isProgressShow = false;
            this.getCustomsDetails = getDetails.result[0];
            this.selectedCustomsLen = getDetails.result.length;
          } else {
            this.isProgressShow = false;
            // customs.isFullViewAccess = false;
            this.getCustomsDetails = customs;
            this.selectedCustomsLen = Object.keys(customs).length;
          }
        },
      });
  }

  selectedListBooking(booking: any, from?: any) {
    this.isProgressShow = true;
    let accountId = this.currentAccountData.accountId;
    let emailId = this.currentUserData.emailAddress;
    let isGroup = this.currentAccountData.isGroup;
    this.bookingServies
      .getBookingDetails(
        accountId,
        emailId,
        booking.forwarder_reference,
        isGroup
      )
      .subscribe({
        next: (getDetails: any) => {
          if (from === 'refresh') {
            this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              true
            );
          }
          if (getDetails.result) {
            this.isProgressShow = false;
            this.getBookingDetails = getDetails.result[0];
            this.selectedBookingLen = getDetails.result.length;
          } else {
            this.isProgressShow = false;
            // booking.isFullViewAccess = false;
            this.getBookingDetails = booking;
            this.selectedBookingLen = Object.keys(booking).length;
          }
        },
      });
  }

  setSessionData() {
    this.userEmail = this.currentUserData.emailAddress
      ? this.currentUserData.emailAddress
      : '';
  }

  shipmentResult(x: any) {
    if (!x.isFullViewAccess) {
      this.popupToggle = true;
      setTimeout(() => {
        this.getShipmentDetails = x;
        this.getAccountDetails = x;
        this.selectedShipmentLen = Object.keys(this.getShipmentDetails).length;
        this.isLoading = false;
        this.publicShareEnrcyptedData = this.shipmentEncrptFilter();
      }, 0);
    } else if (this.currentUserData && x.isFullViewAccess) {
      if (
        this.currentUserData &&
        this.currentUserData.userType === 'systemadmin'
      ) {
        this.switchAccountAdmin(x);
      } else if (
        this.currentUserData &&
        this.currentUserData.userType === 'user'
      ) {
        this.switchAccountUser(x);
      }
    }
  }

  bookingResult(x: any) {
    if (!x.isFullViewAccess) {
      this.popupToggle = true;
      setTimeout(() => {
        this.getBookingDetails = x;
        this.getAccountDetails = x;
        this.selectedBookingLen = Object.keys(this.getBookingDetails).length;
        this.isLoading = false;
      }, 0);
    } else if (this.currentUserData && x.isFullViewAccess) {
      if (
        this.currentUserData &&
        this.currentUserData.userType === 'systemadmin'
      ) {
        this.switchAccountAdmin(x);
      } else if (
        this.currentUserData &&
        this.currentUserData.userType === 'user'
      ) {
        this.switchAccountUser(x);
      }
    }
  }

  customsResult(x: any) {
    if (!x.isFullViewAccess) {
      this.popupToggle = true;
      setTimeout(() => {
        this.getCustomsDetails = x;
        this.getAccountDetails = x;
        this.selectedCustomsLen = Object.keys(this.getCustomsDetails).length;
        this.isLoading = false;
      }, 0);
    } else if (this.currentUserData && x.isFullViewAccess) {
      if (
        this.currentUserData &&
        this.currentUserData.userType === 'systemadmin'
      ) {
        this.switchAccountAdmin(x);
      } else if (
        this.currentUserData &&
        this.currentUserData.userType === 'user'
      ) {
        this.switchAccountUser(x);
      }
    }
  }

  onRefreshShipmentFromDetail(event: any) {
    let shipment = this.getShipmentDetails;
    this.dataSharingService.refreshShipmentDetailsInHeader.next(true);
    if (shipment.isFullViewAccess) {
      this.selectedListShipment(shipment, 'refresh');
    } else if (!shipment.isFullViewAccess) {
      this.onPublicViewRefresh(shipment.forwarder_reference);
    }
  }

  onRefreshBookingFromDetail(event: any) {
    let booking = this.getBookingDetails;
    this.dataSharingService.refreshShipmentDetailsInHeader.next(true);
    if (booking.isFullViewAccess) {
      this.selectedListBooking(booking, 'refresh');
    } else if (!booking.isFullViewAccess) {
      // this.bookingRefreshPublicView();
      this.onPublicViewRefresh(booking.forwarder_reference);
    }
  }

  onRefreshCustomsFromDetail(event: any) {
    let customs = this.getCustomsDetails;
    this.dataSharingService.refreshShipmentDetailsInHeader.next(true);
    if (customs.isFullViewAccess) {
      this.selectedListCustoms(customs, 'refresh');
    } else if (!customs.isFullViewAccess) {
      this.onPublicViewRefresh(customs.forwarder_reference);
    }
  }

  shipmentRefreshPublicView() {
    let shipment = this.getShipmentDetails;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: '',
      parameters: [
        {
          name: 'shipmentNumber',
          value: shipment.forwarder_reference,
        },
      ],
      sort_options: [],
      filter_options: [],
    };
    let oH_Code = shipment.accountId;
    let byGroup = false;
    let isControllingCustomer = false;
    this.shipmentService
      .publicShare(
        oH_Code,
        this.userEmail,
        bodyParam,
        byGroup,
        isControllingCustomer
      )
      .subscribe((res: any) => {
        this.isProgressShow = false;
        this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
        this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
          true
        );
        setTimeout(() => {
          this.getShipmentDetails = res.result[0];
          this.selectedShipmentLen = Object.keys(
            this.getShipmentDetails
          ).length;
        }, 0);
      });
  }

  bookingRefreshPublicView() {
    let booking = this.getBookingDetails;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: '',
      parameters: [
        {
          name: 'shipmentNumber',
          value: booking.forwarder_reference,
        },
      ],
      sort_options: [],
      filter_options: [],
    };
    let oH_Code = booking.accountId;
    let byGroup = false;
    let isControllingCustomer = false;
    this.bookingServies
      .publicShare(
        oH_Code,
        this.userEmail,
        bodyParam,
        byGroup,
        isControllingCustomer
      )
      .subscribe((res: any) => {
        this.isProgressShow = false;
        this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
        this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
          true
        );
        this.getBookingDetails = res.result[0];
        this.selectedBookingLen = Object.keys(this.getBookingDetails).length;
      });
  }

  customsRefreshPublicView() {
    let customs = this.getCustomsDetails;
    let bodyParam = {
      offset: 0,
      fetch: 1,
      search_text: '',
      parameters: [
        {
          name: 'shipmentNumber',
          value: customs.forwarder_reference,
        },
      ],
      sort_options: [],
      filter_options: [],
    };
    let oH_Code = customs.accountId;
    let byGroup = false;
    let isControllingCustomer = false;
    this.customsService
      .publicShare(
        oH_Code,
        this.userEmail,
        bodyParam,
        byGroup,
        isControllingCustomer
      )
      .subscribe((res: any) => {
        this.isProgressShow = false;
        this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
        this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
          true
        );
        setTimeout(() => {
          this.getCustomsDetails = res.result[0];
          this.selectedCustomsLen = Object.keys(this.getCustomsDetails).length;
        }, 0);
      });
  }

  onPublicViewRefresh(shipmentId: any) {
    this.shipmentService
      .shipmentPublicSearchUrl(shipmentId, this.userEmail)
      .subscribe((x: any) => {
        if (x.result) {
          if (x.result.booking && !x.result.shipment_converted_from_booking) {
            this.isProgressShow = false;
            this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              true
            );
            this.getBookingDetails = x.result;
            this.selectedBookingLen = Object.keys(
              this.getBookingDetails
            ).length;
          } else if (
            x.result.forwarder_reference.charAt(0).toLowerCase() === 's'
          ) {
            this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              true
            );
            setTimeout(() => {
              this.getShipmentDetails = x.result;
              this.selectedShipmentLen = Object.keys(
                this.getShipmentDetails
              ).length;
            }, 0);
          } else if (
            x.result.forwarder_reference.charAt(0).toLowerCase() === 'b'
          ) {
            this.isProgressShow = false;
            this.dataSharingService.refreshShipmentDetailsInHeader.next(false);
            this.dataSharingService.refreshShipmentDetailsInHeaderToaster.next(
              true
            );
            setTimeout(() => {
              this.getCustomsDetails = x.result;
              this.selectedCustomsLen = Object.keys(
                this.getCustomsDetails
              ).length;
            }, 0);
          }
        }
      });
  }

  onSearchText(val: any) {
    this.resultNotFound = false;
    this.urlShipmentId = val;
    //this.publicShareShipment(val);
    this.shipmentPublicSearch(val);
    this.location.replaceState('/' + val);
  }

  shipmentEncrptFilter() {
    let selectedFilters = {
      mainFilter: [],
      statusFilter: [],
      transportType: [],
      exceptionType: [],
      watchFilter: [],
      containerType: [],
      origin: {
        selectedValue: {},
        selectedAccount: [],
      },
      destination: {
        selectedValue: {},
        selectedAccount: [],
      },
      dateRange: {
        selectedValue: {},
        selectedDateRange: [],
      },
      shipper: {
        selectedValue: {},
        selectedAccount: [],
      },
      consignee: {
        selectedValue: {},
        selectedAccount: [],
      },
      allLocation: {
        selectedAccount: [
          { origin: [] },
          { destination: [] },
          { pickup: [] },
          { finalDelivery: [] },
        ],
        selectedValue: [
          { originValue: {} },
          { destinationValue: {} },
          { pickupValue: {} },
          { finalDeliveryValue: {} },
        ],
      },
      sorting: {
        sortId: '6',
        selectedValue: {
          column_name: 'first_leg_departure_actual',
          direction: 'desc',
        },
      },
      accountDetail: {
        aliasName: this.getShipmentDetails.account_alias_name,
        oH_Code: this.getShipmentDetails.accountId,
        byGroup: false,
        isControllingCustomer: false,
      },
      shipmentId: this.urlShipmentId,
      type: 'Detail',
    };
    return btoa(JSON.stringify(selectedFilters));
  }

  trackAIPageView() {
    this.appInsightsService.logPageView(
      AI_PageName.Login,
      this.window.location.href
    );
  }

  trackAppInsightEvent(searchText: any, shipemnt: any) {
    let searchResult = 'Not Found';
    let moduleName = '';
    if (shipemnt) {
      moduleName = shipemnt.booking ? 'Booking' : 'Shipment';
      searchResult = 'Found';
    }
    this.appInsightsService.logEvent(AI_CommonEvents.PublicSearch, {
      [AI_CustomProps.ShipmentId]: searchText,
      [AI_CustomProps.ModuleName]: moduleName,
      [AI_CustomProps.SearchResult]: searchResult,
    });
  }
}
